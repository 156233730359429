export interface MagnoliaReferenceImage {
  "@name": string;
  "@path": string;
  "@id": string;
  "@link": string;
  metadata: Metadata;
  renditions: { [key: string]: Rendition };
}

export interface Metadata {
  fileName: string;
  mimeType: string;
  fileSize: number;
  height: number;
  width: number;
  format: string;
  creator: string[];
  date: Date;
  created: Date;
  modified: Date;
}

export interface Rendition {
  mimeType: string;
  link: string;
}

export const redirectTo = (href: string) => {
  if (typeof window === "undefined") return;
  window.location.href = href;
};

export const containPromoHref = (href: string) => {
  const regex = /\/promo-dan-penawaran(?:\/.*)?/;

  const containsPromo = regex.test(href);

  return containsPromo;
};

/** get image url from magnolia reference image on first level.
 * @example:
 * input: object is like this
 * {
      "profileImage": {
        "@name": "alfagabriel ",
        "@path": "/instagrams/alfagabriel-.jpg",
        "@id": "jcr:d83ecd61-e8f2-47a4-8a0c-09ade8dc052d",
        "@link": "/author/dam/jcr:d83ecd61-e8f2-47a4-8a0c-09ade8dc052d/alfagabriel%20.jpg",
        "metadata": {
            "fileName": "alfagabriel .jpg",
            "mimeType": "image/jpeg",
            "fileSize": 3889,
            "height": 150,
            "width": 150,
            "format": "image/jpeg",
            "creator": [
                "superuser"
            ],
            "date": "2024-11-12T10:27:55.210+07:00",
            "created": "2024-11-12T10:27:55.210+07:00",
            "modified": "2024-11-12T10:27:55.210+07:00"
        },
        "renditions": {
            "480": {
                "mimeType": "image/jpeg",
                "link": "/author/.imaging/default/dam/instagrams/alfagabriel-.jpg/jcr:content.jpg"
            },
            "960": {
                "mimeType": "image/jpeg",
                "link": "/author/.imaging/default/dam/instagrams/alfagabriel-.jpg/jcr:content.jpg"
            },
            "1600": {
                "mimeType": "image/jpeg",
                "link": "/author/.imaging/default/dam/instagrams/alfagabriel-.jpg/jcr:content.jpg"
            },
            "nonexistent": {
                "mimeType": "image/jpeg",
                "link": "/author/.imaging/default/dam/instagrams/alfagabriel-.jpg/jcr:content.jpg"
            }
        }
      }
    }
  }
  output: string on "renditions"."480".link
 * @return {string} image url
 *  */

export const getImageUrl = (object: MagnoliaReferenceImage) => {
  const imageUrl = Object.values(object.renditions)[0].link;

  return imageUrl;
};
