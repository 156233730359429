import { FC } from "react";
import { redirectTo } from "src/utils/magnolia";

type LinkProps = {
  href: string;
  children: React.ReactNode;
  onClick?: () => void;
};

const Link: FC<LinkProps> = (props) => {
  const { href, children, onClick = () => {} } = props;

  const handleClick = () => {
    redirectTo(href);
    onClick();
  };

  return <div onClick={handleClick}>{children}</div>;
};

export default Link;
