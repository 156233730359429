import { getCookie } from "../utils/helpers";

export const GetCookie = (name: string | any[]) => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + "=")) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
};

export const GACustomEvent = (event_name, event_action) => {
  const idCsUser = getCookie("idcsu");
  const cookieValue = GetCookie(`_ga_${process.env.ga4key}`)?.substring(6, 16);
  let parameters = {
    event: event_name,
    cd_clientid: cookieValue,
    cd_userid: idCsUser,
    cd_journey_type: "",
    cd_epoch_timestamp: new Date().getTime(),
    cd_is_prod: false,
    event_action: event_action
  };
  GoogleTagManager(parameters);
};

export const GoogleTagManager = (event: any) => {
  (window as any).dataLayer.push(event);
};
