import axios from "axios";

/*
    PROPS
    method is string GET or POST
    url is a string like /this/is/url
    data is an object {key1: value1, key2: value2 }
 */
const fetch = async (method = "", url = "", data = {}, apiURL = false) => {
  let param = {};

  if (method === "GET" && data) {
    param = { params: data };
  } else if (method === "POST" && data) {
    param = { data: data };
  } else if (method === "DELETE" && data) {
    param = { data: data };
  }

  const options = {
    baseURL: `${apiURL ? process.env.apiURL : process.env.baseUrl}/api/v1`,

    // URL API (Incl. query if using GET)
    url: url,

    // GET or POST
    method: method,

    // Default headers for this gateway
    headers: {
      "Content-Type": "application/json",
      Authorization: `${process.env.auth}`
    }

    //timeout 30s
    // timeout: 30000
  };

  // Status means http code 200, 201, 404, 401, etc
  // Please use status code to return which data that you want to shown
  try {
    // send params
    const { status, data } = await axios({ ...options, ...param });

    // return status code and data
    return { status, data };
  } catch (err) {
    console.log(err);
    //get error status and error message in object data
    const { status, data } = err.response;

    // return status code and error message
    return { status, data };
  }
};

export default fetch;
