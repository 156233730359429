import React from "react";
import Link from "next/link";
import LogoRed from "../../../public/static/images/svg/homecredit_red.svg";

const NavbarExternal = () => {
  return (
    <div className="navbar-wrapper">
      <div className="navbar-container">
        <Link href="/">
          <a>
            <img className="navbar-logo" src={LogoRed} alt="logo" />
          </a>
        </Link>
      </div>
      <style jsx>{`
        a {
          text-decoration: none;
          color: var(--color-gray);
        }
        .navbar-wrapper {
          width: 100%;
          display: flex;
          justify-content: center;
          border-bottom: solid 1px var(--color-gray-lighter);
          font-weight: bold;
          background-color: var(--color-white);
          top: 0px;
          position: -webkit-sticky;
          position: sticky;
          z-index: 20;
        }
        .navbar-logo {
          margin: 10px;
          height: 16px;
        }
        .navbar-logo:hover {
          cursor: pointer;
        }
        .navbar-container {
          height: 64px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 960px;
        }
      `}</style>
    </div>
  );
};

export default NavbarExternal;
