import React from "react";
import ojkIcon from "../../../public/static/images/png/hci_under_ojk_centered_white.png";

const FooterExternal = () => {
  return (
    <React.Fragment>
      <div className="footer-container-wrapper">
        <div className="footer-container">
          <img
            src={ojkIcon}
            style={{ height: "34px", margin: "20px" }}
            alt="ojk-logo"
            className="footer-container-ojk-logo"
          />
          <div className="copyright">
            <span className="copyright-logo">&#9400;</span>
            <span className="copyright-copy">2022</span>
          </div>
        </div>
      </div>
      <style jsx>{`
        .footer-container-wrapper {
          display: flex;
          justify-content: center;
          background-color: var(--color-black);
          width: 100%;
          align-items: center;
        }
        .footer-container {
          width: 100%;
          padding: 10px;
          color: var(--color-gray-lighter);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          max-width: 960px;
        }
        .footer-container-ojk-logo {
          text-align: center;
        }
        .copyright {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .copyright-logo {
          font-size: 10px;
          margin-right: 4px;
        }
        .copyright-copy {
          font-size: 12px;
        }
      `}</style>
    </React.Fragment>
  );
};

export default FooterExternal;
