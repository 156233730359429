//DATE
export const yearConst = new Date().getFullYear();

//HEADER
export const HEADER_DEFAULT = "HEADER_DEFAULT";
export const HEADER_EXTERNAL = "HEADER_EXTERNAL";
export const HEADER_NONE = "HEADER_NONE";

//FOOTER
export const FOOTER_DEFAULT = "FOOTER_DEFAULT";
export const FOOTER_EXTERNAL = "FOOTER_EXTERNAL";
export const FOOTER_NONE = "FOOTER_NONE";
